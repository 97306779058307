import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { Map, TileLayer } from 'react-leaflet';

import LocationMarker from './LocationMarker';
import Viewer from './Viewer';

import '../../css/Map.css';
import { config } from '../config';

const MAPTILES = "https://api.mapbox.com/styles/v1/deborger/ck38o3mc81f771cpj5ezv1p4j/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZGVib3JnZXIiLCJhIjoiY2ozNXp2d25oMDAydzJ3bzA4ZTR0ZGZ3NyJ9.70WTGSUCBJ7rChZVagSEfQ";
const MOBILE_ZOOM = 10;

class MapComponent extends Component {
    constructor() {
        super();
        this.state = {
            lat: 51.050,
            lng: 2.774668,
            openProject: null,
            play: true,            
            zoom: 11,
            mouse: {
                x: null,
                y: null
            },
            svgStyle: {
                transform: `translate(0px, 0px)`
            }
        }
        this.locations = config.projects;
    }
    

    onCloseProject = () => {
        console.log('close project');
        this.setState({
            openProject: null
        })
    }

    onMarkerClick = (id) => {
        console.log('Marker clicked', id);
        this.setState({
            openProject: this.locations[id],
            svgStyle: {
                transform: `translate(${this.state.mouse.x}px, ${this.state.mouse.x}px)`
            }
        });
        console.log('State', this.state);

    }

    onMouseMove = (e) => {
        console.log('Mouse x', e.pageX);
        console.log('Mouse y', e.pageY);
        this.setState({
            mouse: {
                x: e.pageX,
                y: e.pageY
            }
        });
    }

    render() {
        const position = [this.state.lat, this.state.lng]
        let zoom = this.state.zoom;

        if(isMobile) {
            zoom = MOBILE_ZOOM;
        }

        return (
            <div className="Map" onMouseMove={(e) => this.onMouseMove(e)}>
                <Map 
                    center={position}
                    doubleClickZoom={false}
                    closePopupOnClick={false}
                    dragging={true}
                    zoomSnap={false} 
                    zoomDelta={false} 
                    trackResize={false}
                    touchZoom={true}
                    scrollWheelZoom={true}
                    zoom={zoom}
                    zoomControl={false}
                >
                    <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url={MAPTILES}
                    />
                    {this.locations.map( (location, index) => (
                        <LocationMarker key={`marker_${index}`} center={location.coords} id={index} onClick={(id) => this.onMarkerClick(id)}/>
                    ))}
                </Map>
                
                {
                    this.state.openProject ? <Viewer onClose={() => this.onCloseProject()} project={this.state.openProject} /> : null
                }
            </div>
        )
    }
}

export default MapComponent;