export const config = {
    baseUrl: 'https://iedereendichter.s3.eu-central-1.amazonaws.com/',
    menuAssets1: [
        {
            projectId: 0,
            projectTitle: 'De Panne',
            orientation: 'portrait',
            url: '/assets/img/menu/depanne_001.png'
        },
        {
            projectId: 0,
            projectTitle: 'De Panne',
            orientation: 'portrait',
            url: '/assets/img/menu/depanne_002.png'
        },
        {
            projectId: 0,
            projectTitle: 'De Panne',
            orientation: 'portrait',
            url: '/assets/img/menu/depanne_003.png'
        },
        {
            projectId: 6,
            projectTitle: 'Nieuwpoort',
            orientation: 'landscape',
            url: '/assets/img/menu/nieuwpoort_001.png'
        },
        {
            projectId: 6,
            projectTitle: 'Nieuwpoort',
            orientation: 'landscape',
            url: '/assets/img/menu/nieuwpoort_002.png'
        },
        {
            projectId: 6,
            projectTitle: 'Nieuwpoort',
            orientation: 'portrait',
            url: '/assets/img/menu/nieuwpoort_007.png'
        },
        {
            projectId: 6,
            projectTitle: 'Nieuwpoort',
            orientation: 'portrait',
            url: '/assets/img/menu/nieuwpoort_008.png'
        },
        {
            projectId: 6,
            projectTitle: 'Nieuwpoort',
            orientation: 'portrait',
            url: '/assets/img/menu/nieuwpoort_009.png'
        },
        {
            projectId: 8,
            projectTitle: 'Koksijde',
            orientation: 'portrait',
            url: '/assets/img/menu/koksijde_001.png'
        }
    ],
    menuAssets2: [
        {
            projectId: 0,
            projectTitle: 'De Panne',
            orientation: 'portrait',
            url: '/assets/img/menu/depanne_004.png'
        },
        {
            projectId: 0,
            projectTitle: 'De Panne',
            orientation: 'portrait',
            url: '/assets/img/menu/depanne_005.png'
        },
        {
            projectId: 0,
            projectTitle: 'De Panne',
            orientation: 'portrait',
            url: '/assets/img/menu/depanne_006.png'
        },
        {
            projectId: 6,
            projectTitle: 'Nieuwpoort',
            orientation: 'landscape',
            url: '/assets/img/menu/nieuwpoort_003.png'
        },
        {
            projectId: 6,
            projectTitle: 'Nieuwpoort',
            orientation: 'landscape',
            url: '/assets/img/menu/nieuwpoort_004.png'
        },
        {
            projectId: 6,
            projectTitle: 'Nieuwpoort',
            orientation: 'landscape',
            url: '/assets/img/menu/nieuwpoort_005.png'
        },
        {
            projectId: 6,
            projectTitle: 'Nieuwpoort',
            orientation: 'landscape',
            url: '/assets/img/menu/nieuwpoort_006.png'
        },
        {
            projectId: 8,
            projectTitle: 'Koksijde',
            orientation: 'portrait',
            url: '/assets/img/menu/koksijde_002.png'
        },
        {
            projectId: 8,
            projectTitle: 'Koksijde',
            orientation: 'portrait',
            url: '/assets/img/menu/koksijde_003.png'
        },
        {
            projectId: 8,
            projectTitle: 'Koksijde',
            orientation: 'portrait',
            url: '/assets/img/menu/koksijde_004.png'
        }
    ],
    menuAssets3: [
        {
            projectId: 3,
            projectTitle: 'Lo-Reninge',
            orientation: 'portrait',
            url: '/assets/img/menu/loreninge_001.png'
        },
        {
            projectId: 3,
            projectTitle: 'Lo-Reninge',
            orientation: 'portrait',
            url: '/assets/img/menu/loreninge_002.png'
        },
        {
            projectId: 3,
            projectTitle: 'Lo-Reninge',
            orientation: 'portrait',
            url: '/assets/img/menu/loreninge_003.png'
        },
        {
            projectId: 5,
            projectTitle: 'Houthulst',
            orientation: 'landscape',
            url: '/assets/img/menu/houthulst_002.png'
        },
        {
            projectId: 5,
            projectTitle: 'Houthulst',
            orientation: 'landscape',
            url: '/assets/img/menu/houthulst_003.png'
        },
        {
            projectId: 5,
            projectTitle: 'Houthulst',
            orientation: 'landscape',
            url: '/assets/img/menu/houthulst_004.png'
        },
        {
            projectId: 5,
            projectTitle: 'Houthulst',
            orientation: 'landscape',
            url: '/assets/img/menu/houthulst_005.png'
        },
        {
            projectId: 1,
            projectTitle: 'Veurne',
            orientation: 'portrait',
            url: '/assets/img/menu/veurne_001.png'
        },
        {
            projectId: 1,
            projectTitle: 'Veurne',
            orientation: 'portrait',
            url: '/assets/img/menu/veurne_002.png'
        },
        {
            projectId: 1,
            projectTitle: 'Veurne',
            orientation: 'portrait',
            url: '/assets/img/menu/veurne_003.png'
        }
    ],
    menuAssets4: [
        {
            projectId: 5,
            projectTitle: 'Houthulst',
            orientation: 'landscape',
            url: '/assets/img/menu/houthulst_001.png'
        },
        {
            projectId: 5,
            projectTitle: 'Houthulst',
            orientation: 'landscape',
            url: '/assets/img/menu/houthulst_008.png'
        },
        {
            projectId: 3,
            projectTitle: 'Lo-Reninge',
            orientation: 'portrait',
            url: '/assets/img/menu/loreninge_004.png'
        },
        {
            projectId: 3,
            projectTitle: 'Lo-Reninge',
            orientation: 'landscape',
            url: '/assets/img/menu/loreninge_005.png'
        },
        {
            projectId: 3,
            projectTitle: 'Lo-Reninge',
            orientation: 'landscape',
            url: '/assets/img/menu/loreninge_006.png'
        },
        {
            projectId: 5,
            projectTitle: 'Houthulst',
            orientation: 'landscape',
            url: '/assets/img/menu/houthulst_006.png'
        },
        {
            projectId: 5,
            projectTitle: 'Houthulst',
            orientation: 'portrait',
            url: '/assets/img/menu/houthulst_007.png'
        },
        {
            projectId: 1,
            projectTitle: 'Veurne',
            orientation: 'portrait',
            url: '/assets/img/menu/veurne_004.png'
        },
        {
            projectId: 1,
            projectTitle: 'Veurne',
            orientation: 'portrait',
            url: '/assets/img/menu/veurne_005.png'
        },
        {
            projectId: 1,
            projectTitle: 'Veurne',
            orientation: 'landscape',
            url: '/assets/img/menu/veurne_006.png'
        }
    ],
    menuAssets5: [
        {
            projectId: 2,
            projectTitle: 'Alveringem',
            orientation: 'landscape',
            url: '/assets/img/menu/alveringem_001.png'
        },
        {
            projectId: 6,
            projectTitle: 'Nieuwpoort',
            orientation: 'landscape',
            url: '/assets/img/menu/nieuwpoort_016.png'
        },
        {
            projectId: 2,
            projectTitle: 'Alveringem',
            orientation: 'landscape',
            url: '/assets/img/menu/alveringem_002.png'
        },
        {
            projectId: 2,
            projectTitle: 'Alveringem',
            orientation: 'landscape',
            url: '/assets/img/menu/alveringem_003.png'
        },
        {
            projectId: 4,
            projectTitle: 'Diksmuide',
            orientation: 'landscape',
            url: '/assets/img/menu/diksmuide_001.png'
        },
        {
            projectId: 2,
            projectTitle: 'Alveringem',
            orientation: 'portrait',
            url: '/assets/img/menu/alveringem_004.png'
        },
        {
            projectId: 2,
            projectTitle: 'Alveringem',
            orientation: 'portrait',
            url: '/assets/img/menu/alveringem_005.png'
        },
        {
            projectId: 4,
            projectTitle: 'Diksmuide',
            orientation: 'landscape',
            url: '/assets/img/menu/diksmuide_002.png'
        },
        {
            projectId: 4,
            projectTitle: 'Diksmuide',
            orientation: 'portrait',
            url: '/assets/img/menu/diksmuide_003.png'
        },
        {
            projectId: 2,
            projectTitle: 'Alveringem',
            orientation: 'portrait',
            url: '/assets/img/menu/alveringem_006.png'
        },
    ],
    menuAssets6: [
        {
            projectId: 6,
            projectTitle: 'Nieuwpoort',
            orientation: 'landscape',
            url: '/assets/img/menu/nieuwpoort_010.png'
        },
        {
            projectId: 6,
            projectTitle: 'Nieuwpoort',
            orientation: 'landscape',
            url: '/assets/img/menu/nieuwpoort_013.png'
        },
        {
            projectId: 6,
            projectTitle: 'Nieuwpoort',
            orientation: 'portrait',
            url: '/assets/img/menu/nieuwpoort_011.png'
        },
        {
            projectId: 6,
            projectTitle: 'Nieuwpoort',
            orientation: 'portrait',
            url: '/assets/img/menu/nieuwpoort_012.png'
        },
        {
            projectId: 6,
            projectTitle: 'Nieuwpoort',
            orientation: 'landscape',
            url: '/assets/img/menu/nieuwpoort_014.png'
        },
        {
            projectId: 4,
            projectTitle: 'Diksmuide',
            orientation: 'landscape',
            url: '/assets/img/menu/diksmuide_004.png'
        },
        {
            projectId: 6,
            projectTitle: 'Nieuwpoort',
            orientation: 'portrait',
            url: '/assets/img/menu/nieuwpoort_015.png'
        },
        {
            projectId: 4,
            projectTitle: 'Diksmuide',
            orientation: 'landscape',
            url: '/assets/img/menu/diksmuide_005.png'
        },
        {
            projectId: 4,
            projectTitle: 'Diksmuide',
            orientation: 'portrait',
            url: '/assets/img/menu/diksmuide_006.png'
        },
        {
            projectId: 4,
            projectTitle: 'Diksmuide',
            orientation: 'portrait',
            url: '/assets/img/menu/diksmuide_007.png'
        },
    ],
    projects: [
        {
            id: 0,
            title: 'De Panne',
            coords: {
                lat: 51.079469,
                lng: 2.597058
            },
            text: [
                "noem mij niet je vrouw",
                "wanneer je stapt over mijn aangestampte zand",
                "waaronder tourelles gevlekte koffieboontjes niets zo breekbaar",
                "als het aanspoelen van een verbrand gezicht",
                "dat in uitgeweken talen naar een kussen zoekt",
                "tussen mijn banken, muien, bos",
                "",
                "noem mij niet je vrouw",
                "ik loop niet zomaar met je mee geloof jij dat",
                "deze plek bepaalt wie je bent",
                "hoe open hoe wakker hoe wit de zee ",
                "",
                "het waait en ik draag het licht in de kom van mijn heup",
                "",
                "het waait en ik verberg de tijd als wier in mijn binnenzak",
                "",
                "het waait en ik hoor hoe de aflandige wind alle geruchten de zee in veegt",
                "de winter over grenzen trekt, zijn tocht deze uithoek doet zwijgen",
                "",
                "noem mij niet je vrouw",
                "ook al sta ik ’s zomers met mijn geblakerd vel",
                "mijn pigmentvlekken mijn badpakbillen ",
                "onopgekleed vanop een duintop te turen",
                "",
                "ik wacht en wuif ", 
                "naar wie op zee ",
                "wie trekt weg",
                "wie komt terug mee  "
            ],
            assets: [
                {
                    type: 'stack',
                    placement: 'left',
                    images: [
                        '/assets/0/depanne_stack_001.jpg',
                        '/assets/0/depanne_stack_002.jpg',
                        '/assets/0/depanne_stack_003.jpg',
                        '/assets/0/depanne_stack_004.jpg',
                        '/assets/0/depanne_stack_005.jpg',
                        '/assets/0/depanne_stack_006.jpg',
                        '/assets/0/depanne_stack_007.jpg',
                        '/assets/0/depanne_stack_008.jpg',
                    ]
                },
                {
                    type: 'video-image',
                    placement: 'right',
                    image: '/assets/0/depanne_img_001.jpg',
                    video: '/assets/0/depanne_video_001.mp4'
                },
                {
                    type: 'images',
                    size: 4,
                    images: [
                        '/assets/0/DP1.jpg',
                        '/assets/0/DP2.jpg',
                        '/assets/0/DP3.jpg',
                        '/assets/0/DP4.jpg',
                        '/assets/0/DP5.jpg',
                    ]
                },
                {
                    type: 'video-image',
                    placement: 'left',
                    image: '/assets/0/depanne_img_002.jpg',
                    video: '/assets/0/depanne_video_002.mp4'
                },
                {
                    type: 'stack',
                    placement: 'right',
                    images: [
                        '/assets/0/depanne_stack_009.jpg',
                        '/assets/0/depanne_stack_010.jpg',
                        '/assets/0/depanne_stack_011.jpg',
                        '/assets/0/depanne_stack_012.jpg',
                        '/assets/0/depanne_stack_013.jpg',
                        '/assets/0/depanne_stack_014.jpg',
                        '/assets/0/depanne_stack_015.jpg',
                    ]
                },
            ],
            video: 'assets/video/DEPANNE.mp4'
        },
        {
            id: 1,
            title: 'Veurne',
            coords: {
                lat: 51.071542,
                lng: 2.663375
            },
            text: [
                "geef mij een uitzicht",
                "",
                "de blote wind",
                "",
                "over de moeren", 
                "",
                "waar mijn lichaam zich opricht",
                "",
                "en stijgt boven het gerasterd land",
                "",
                "kleien canvas waar witte vogels",
                "",
                "als tanden oplichten in de schemer",
                "",
                "de grens hijgt kortademig",
                "",
                "de wolken nog onbeschaafd",
                "",
                "zie verderop hoe kinderbenen racen",
                "",
                "over de kasseien kopjes van de stad",
                "",
                "het lenige accent van zij die nieuw", 
                "",
                "en vurig arriveren, verhalen",
                "",
                "traag geslagen tot steen",
                "",
                "hoor de hoofdloze lichamen",
                "",
                "met hun kraters en talismannen en schorre stemmen",
                "",
                "echoën in putten en kerken",
                "",
                "in de nacht hangen schouder aan schouder",
                "",
                "de helblauwe huizen",
                "",
                "dommelen zwijgend  in"
            ],
            assets: [],
            video: 'assets/video/VEURNE.mp4'
        },
        {
            id: 2,
            title: 'Alveringem',
            coords: {
                lat: 51.012505,
                lng: 2.710174
            },
            text: [
                "ik zit in de tuin en eet kersen",
                "en zie hoe deze grond mijn wachtwoorden heeft bewaard",
                "oude kogels, lippen van papavers",
                "onaangeroerd heeft bewaard",
                "",
                "het was een stil jaar",
                "",
                "zegt de buurvrouw terwijl ze de ronde van het onkruid doet",
                "tuurt naar de pulserende kolen aan de horizon",
                "",
                "voel jij ook die dalende druk",
                "dat fijn stof dat ons na al die tijd gevonden heeft",
                "ze drukt haar handen in de aarde, en haar knieën, en haar gezicht",
                "",
                "het was een stil jaar",
                "",
                "ik luisterde naar mijn moeder die verdrietig was om mijn broer",
                "die verdrietig was om mijn vader die verdrietig was om mij we zwegen",
                "",
                "rouwen op het ritme van de natuur is zo slecht nog niet",
                "zegt de laatste boer die tegen de muur zijn facturen uitklopt",
                "",
                "de beesten hebben me nodig, liefde geef ik in de vorm",
                "van een kilo courgettes, het slijmspoor van slakken na onweer stelt me gerust ",
                "",
                "net zoals het feit dat ze niet langer dan twaalf maanden hoeven te leven ",
                "hoeveel lichter, hoeveel vrijer je zou ademhalen en maakt het dan nog uit ",
                "",
                "welk dorp, welk huis",
                "welke trots je op je draagt"
            ],
            assets: [],
            video: 'assets/video/ALVERINGEM.mp4'
        },
        {
            id: 3,
            title: 'Lo-Reninge',
            coords: {
                lat: 50.966380,
                lng: 2.781898
            },
            text: [
                "tot hier loopt de straat daarachter",
                "het plein de kerk daarachter",
                "twee paar trillende handen",
                "",
                "(en hoewel Mady daarnet nog zwoer ",
                "de straten op te tillen eens goed uit te schudden", 
                "is het vooralsnog stil in deze stad",
                "die murmelt en zeeziek wiegt",
                "tussen haar weiden)",
                "",
                "de man zegt",
                "hier valt zelfs je schaduw op ",
                "ook al koester ik je geheimen zoals je geaderd vel ",
                "ook al blijf je het liefst in je huis je hoofd ",
                "met zijn eeuwenoude gevel erfgoed ",
                "waarmee jij je voeten inbindt",
                "",
                "hoor je verderop het gebabbel van de markt ",
                "waar men op elkaars knapperige namen kauwt ",
                "waar aandacht zorgvuldig",
                "in plastic bakjes wordt uitgedeeld?",
                "",
                "de vrouw zegt",
                "je hoeft me niet in de ogen te kijken",
                "ik begrijp je het best als je zwijgt, ruisen wil ik",
                "als de rivier onder de kasseien onder de Westpoort",
                "onder onze houten koten waar vrijheid",
                "in weckpotten ligt te gisten",
                "",
                "zie je niet hoe ik drink van dit licht",
                "hoe die zwerm spreeuwen optrekt",
                "duikt een bocht maakt kletterend",
                "voor geen toren zwicht?"
            ],
            assets: [
                {
                    type: 'images',
                    size: 4,
                    images: [
                        '/assets/3/loreninge_wiebenik.jpg'
                    ]
                },
                {
                    type: 'audio',
                    id: '726193762',
                    title: 'Lo-Reninge workshop basisschool'
                },
                {
                    type: 'images',
                    size: 2,
                    images: [
                        '/assets/3/kinderenlo-reninge.jpg',
                        '/assets/3/kinderenlo-reninge2.jpg',
                        '/assets/3/kinderenlo-reninge3.jpg',
                        '/assets/3/kinderenlo-reninge4.jpg',
                        '/assets/3/kinderenlo-reninge5.jpg',
                        '/assets/3/kinderenlo-reninge6.jpg',
                        '/assets/3/kinderenlo-reninge7.jpg',
                        '/assets/3/kinderenlo-reninge8.jpg',
                        '/assets/3/kinderenlo-reninge9.jpg',
                    ]
                },
            ],
            video: 'assets/video/LORENINGE.mp4'
        },
        {
            id: 4,
            title: 'Diksmuide',
            coords: {
                lat: 51.036434,
                lng: 2.864254
            },
            text: [
                "op het kruispunt van klei en zand",
                "werd ik geboren",
                "uit een gezin van littekens en wild vlees",
                "",
                "geen vlakte zo vlak",
                "dat het niet droomt van een stad",
                "van samengehokte huizen, een trotse tuin",
                "om ‘s zomers naar de notelaar te reizen",
                "geef me geen Costa Brava",
                "slechts in de winter wil ik een zee",
                "",
                "nu stap ik elke dag",
                "over de hitte van dit stenen plein",
                "waaronder scherven knarsen",
                "dat de bodem van onze harten",
                "zich niet zomaar laat bewerken",
                "",
                "doorheen mijn ruggengraat stromen",
                "de melodieën van arbeid en geld",
                "ik zie op tegen de toren, de vuist die eist",
                "het recht op geheimen en verstoven tijd", 
                "",
                "meer en meer alleen",
                "zwerf ik over virtuele wegen",
                "vecht ik tegen de bange cadans van mijn genen",
                "",
                "aan de Ijzer trek ik mijn schoenen uit",
                "strooi kiezels tussen het riet",
                "over de open handen van koude soldaten",
                "",
                "elke dag kijk ik uit het raam",
                "noteer welke vogels en het weer",
                "schrap in mijn mond nog een woord",
                "waarin ik geen verkoeling meer vind",
                "",
                "tot alles eindelijk stil",
                "eindelijk alles stil",
                "",
                "zie ik plots hoe de notelaar",
                "een kerselaar geworden is"
            ],
            assets: [
                {
                    type: 'audio',
                    id: '726192904',
                    title: 'Workshop Oostkerke'
                }
            ],
            video: 'assets/video/DIKSMUIDE_alt.mp4'
        },
        {
            id: 5,
            title: 'Houthulst',
            coords: {
                lat: 50.975285,
                lng: 2.943134
            },
            text: [
                "dus we vormen een kring", 
                "armen in elkaar gehaakt",
                "en spreken over vroeger hoe we",
                "vochten hoe we wonnen:",
                "",
                "kermistrofeeën blote handen en een stier van 1000 kilo",
                "angst danst als wespen om ons heen maar wij",
                "kijken naar beneden en zien hoe het leven overvloedig",
                "naar ons lacht ook de obussen op de akkers", 
                "stellen ons gerust",
                "",
                "dus we vormen een kring",
                "rond dit witte dorp van auto’s, rivieren",
                "bossen van vrijen en eenzaamheid",
                "we herhalen onze woorden, dromen ‘s nachts van elkaars",
                "zacht vervagend gezicht",
                "",
                "tot op een dag een TL-lamp aanknipt",
                "plots dit dorp verlicht, de kinderen",
                "in de tuinen nieuwsgierig onder", 
                "de honkvaste benen door kruipen",
                "over het grasplein rennen, zingen", 
                "",
                "wie tikt op onze rug?",
                "wie spreekt in nieuwe talen?", 
                "wat zweeft daar door de lucht?", 
                "wie maakt de knopen los?"
            ],
            assets: [
                {
                    type: 'audio',
                    id: '726191062',
                    title: 'Houthulst workshop basisschool'
                },
                {
                    type: 'images',
                    size: 4,
                    images: [
                        '/assets/5/workshophouthulst1.jpg',
                        '/assets/5/workshophouthulst2.jpg',
                        '/assets/5/workshophouthulst3.jpg',
                        '/assets/5/workshophouthulst4.jpg',
                        '/assets/5/workshophouthulst5.jpg',
                        '/assets/5/workshophouthulst6.jpg',
                        '/assets/5/workshophouthulst7.jpg',
                        '/assets/5/workshophouthulst8.jpg',
                        '/assets/5/workshophouthulst9.jpg',
                    ]
                }
            ],
            video: 'assets/video/HOUTHULST.mp4'
        },
        {
            id: 6,
            title: 'Nieuwpoort-stad',
            coords: {
                lat: 51.126711,
                lng: 2.767767
            },
            text: [
                "doe maar gewoon zeggen onze moeders", 
                "wanneer ze ja knikkend de deuren sluiten, zolang we weten",
                "van waar we komen en de tuin voldoende mest",
                "",
                "doe maar gewoon herhalen onze moeders",
                "als een toestel dat blijft tikken als het uitgeschakeld is",
                "",
                "wanneer ben je weer vragen onze moeders",
                "die hun zuiverste taal verbergen in hun schort",
                "",
                "terwijl ze snijden: kabeljauw, aardappelen, de wortels wenen",
                "vraag niet om troost maar om brood en zout",
                "",
                "doe maar gewoon zeggen onze moeders",
                "die hun rechte lippen laten schemeren",
                "",
                "door ons gelaat, die offeren: knieën, zeepruwe handen",
                "hun tongloos lichaam wappert aan de draad",
                "",
                "doe maar gewoon zeggen onze moeders",
                "die hun dochters toch niet tussen dichters",
                "",
                "niet tussen vissers, niet tussen vreemden",
                "liever niet richting zee laten gaan"
            ],
            assets: [
                {
                    type: 'stack',
                    placement: 'right',
                    images: [
                        '/assets/6/nieuwpoort_img_001.jpg',
                        '/assets/6/nieuwpoort_img_002.jpg',
                        '/assets/6/nieuwpoort_img_003.jpg',
                        '/assets/6/nieuwpoort_img_004.jpg',
                    ]
                },
                {
                    type: 'title',
                    text: 'Gedichten van de klas 6HS, basisschool De Vierboete'
                },
                {
                    type: 'images',
                    size: 2,
                    images: [
                        '/assets/6/nieuwpoort_gedicht_001.jpg',
                        '/assets/6/nieuwpoort_gedicht_002.jpg',
                        '/assets/6/nieuwpoort_gedicht_003.jpg',
                        '/assets/6/nieuwpoort_gedicht_004.jpg',
                        '/assets/6/nieuwpoort_gedicht_005.jpg',
                        '/assets/6/nieuwpoort_gedicht_006.jpg',
                        '/assets/6/nieuwpoort_gedicht_007.jpg',
                        '/assets/6/nieuwpoort_gedicht_008.jpg',
                    ]
                },
                {
                    type: 'images',
                    size: 4,
                    images: [
                        '/assets/6/nieuwpoort_img_003.jpg'
                    ]
                },
                {
                    type: 'images',
                    size: 2,
                    images: [
                        '/assets/6/nieuwpoort_gedicht_009.jpg',
                        '/assets/6/nieuwpoort_gedicht_010.jpg',
                        '/assets/6/nieuwpoort_gedicht_011.jpg',
                        '/assets/6/nieuwpoort_gedicht_012.jpg',
                        '/assets/6/nieuwpoort_gedicht_013.jpg',
                        '/assets/6/nieuwpoort_gedicht_014.jpg',
                        '/assets/6/nieuwpoort_gedicht_015.jpg',
                        '/assets/6/nieuwpoort_gedicht_016.jpg',
                    ]
                },
            ],
            video: 'assets/video/NIEUWPOORTSTAD.mp4'
        },
        {
            id: 7,
            title: 'Nieuwpoort-bad',
            coords: {
                lat: 51.147297,
                lng: 2.716836
            },
            text: [
                "ze weet van waar je bent",
                "door de manier waarop je haar naam uitspreekt",
                "liggen je wortels hier als een vergeten flipflop door krabben overspoeld",
                "arriveerde je pas later denkend dat je de enige hopeloze",
                "niet hier - dit eindpunt trekt massastranding aan",
                "",
                "ze zegt ze moeten je eerst aanvaarden",
                "blaast haar mist uit als een rokende madame",
                "wanneer je in de nacht je longen zoekt, de kooien opengooit",
                "om haar je panters te laten zien",
                "die bang zijn van de slaap",
                "het gezicht van een dood die in haar boezem woont",
                "",
                "ze zegt er zijn manieren van zwemmen",
                "langs de jaren die we willen bezitten alsof het keukentoestellen zijn",
                "",
                "je schroeft jouw opgebrande ledematen los",
                "gooit ze één voor één over de reling van dit staketsel hoor je ze",
                "plonzen als boodschappentassen",
                "",
                "ze zegt ze zal alles slikken",
                "jij nooit meer en eindelijk terug nog een laatste keer beginnen",
                "zegt ze zo zacht",
                "opnieuw  opnieuw  opnieuw"
            ],
            assets: [],
            video: 'assets/video/NIEUWPOORT.mp4'
        },
        {
            id: 8,
            title: 'Koksijde',
            coords: {
                lat: 51.109314,
                lng: 2.644921
            },
            text: [
                "Voor B.",
                "",
                "",
                "",
                "de man is een huis van geduld",
                "in een dorp dat langzaam zinkt",
                "",
                "zien we ons in de ogen",
                "van overmoedige dakdekkers",
                "van piepende fietsen rond rotondes",
                "",
                "de straten stretchen moeizaam",
                "kijken bij doortocht de andere kant op",
                "",
                "waar met steigers en vergunningen gevliegerd wordt",
                "",
                "het waait van over duintoppen tot in de botten",
                "van murmelende vissers waarvoor ooit op blote voeten werd gemarcheerd",
                "",
                "hoeveel bakkers hoeveel café’s hoeveel dichters",
                "heb je nodig om een vuist te maken",
                "",
                "dat wij nog bestaan",
                "uit huizen en verhaallijnen",
                "verstrikte netten en een benauwde borstkas",
                "",
                "waarmee we ’s avonds als garnalen",
                "in onze bedden liggen",
                "en baden in sepia",
                "",
                "berusten in de grote ogen",
                "van doorschijnend geschilderde vrouwen",
                "",
                "denken aan de heimwee",
                "de vechtlust van de Zouaven"
            ],
            assets: [],
            video: 'assets/video/KOKSIJDE.mp4'
        },
    ]
}