import React, { Component } from 'react';
import { Flipper, Flipped } from 'react-flip-toolkit'

import '../../css/Stack.css';

const BASE_URL = 'https://iedereendichter.s3.eu-central-1.amazonaws.com';

const staggerConfig = {
    stagger: {
        speed: 0
    }
}

class Stack extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
        this.images = props.images
    }

    toggleOpened = () => {
        this.setState({
            open: !this.state.open
        });
    }

    render() {
        const _this = this;
        let height = 'single';
        if(this.images.length > 4) {
            height = 'double';
        }

        console.log('Public', process.env.PUBLIC_URL);
        let childElements = this.images.map(function(image){
            return (
                <Flipped flipId={`stack_${image}`} key={`stack_${image}`} transformOrigin={"50% 50%"}>
                    <img className={`StackImage ${_this.props.placement}`} src={BASE_URL + image} alt={`stack ${image}`}/>
                </Flipped>
            )
        });

        return (
            <div className={this.state.open ? `Stack open ${height}` : `Stack ${height}`} onClick={() => this.toggleOpened()}>
                <Flipper flipKey={this.state.open} spring="veryGentle" staggerConfig={staggerConfig}>
                    { childElements }
                </Flipper>                
            </div>
        )
    }
}

export default Stack;