import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../../css/Project.css';

import imgLogo from '../../assets/project_img_logo.jpg';
import img1 from '../../assets/project_img_001.jpg';
import img2 from '../../assets/project_img_002.jpg';
import img3 from '../../assets/project_img_003.jpg';
import img4 from '../../assets/project_img_004.jpg';

function Project() {
    return(
        <div className="Project page">
            <Container>
                <Row>
                    <Col sm={6} xs={12}>
                        <img className="ProjectLogo" src={imgLogo} alt="Iedereen dichter logo"/>
                    </Col> 
                    <Col sm={6} xs={12}>
                        <p>
                            Voor het project Iedereen
                            Dichter trok auteur Astrid
                            Haerens deze lente naar de
                            Westhoek. Aan de hand van
                            publieke interventies en open
                            schrijfsessies ging ze op zoek naar
                            de hartslag van de streek, om
                            deze in poëzie te gieten. Het
                            thema van het project is Het
                            verborgene: alles wat je niet met
                            het blote oog kan zien.
                        </p>
                    </Col> 
                </Row>
                <Row>
                    <Col sm={6} xs={12}>
                        <p>
                        Tijdens de maanden mei en juni verkende Astrid
                        acht dorpen en steden: Nieuwpoort, De Panne,
                        Houthulst, Veurne, Diksmuide, Alveringem,
                        Koksijde en Lo-Reninge. Op elke plek ging ze op
                        een andere, unieke manier te werk, steeds met
                        open ogen en oren, steeds op zoek naar
                        ontmoetingen en verhalen. Zo zat ze op markten,
                        in café’s, op de zeedijk en het staketsel, bij
                        mensen thuis, in een caravan, op een boot en op
                        de fiets. Ze hield schrijfworkshops in basisscholen,
                        op een historische tram en in het pittoreske
                        dorpje Oostkerke. Astrid sprak met meer dan 200
                        inwoners uit de streek: van postbodes tot
                        burgemeesters, van kunstenaars tot landbouwers.
                        Die ontmoetingen vertaalde ze in poëzie en beeld.
                        </p>
                    </Col> 
                    <Col sm={6} xs={12}>
                        <img src={img1} alt="Iedereen dichter tekening"/>
                    </Col> 
                </Row>
                <Row>
                    <Col sm={6} xs={12}>
                        <img src={img2} alt="Iedereen dichter tekening"/>
                    </Col> 
                    <Col sm={6} xs={12}>
                        <p>
                            De gedichten krijgen vorm in dit online
                            poëzieplattegrond. Hier is, naast gedichten en een
                            film i.s.m. filmmaker Leonardo Van Dijl, ook het
                            werkproces op terug te vinden: portretten van
                            inwoners, podcasts van workshops, gedichten
                            geschreven door de mensen uit de streek,
                            enzovoort. Zo wordt deze poëzieplattegrond een
                            open bibliotheek, multimediaal en veelstemmig,
                            een complex radarwerk, zoals het leven zelf.
                        </p>
                        <p>
                            Daarnaast werden de gedichten in een boekje en op
                            affiches gepubliceerd. Deze zijn verkrijgbaar in de
                            bibliotheken en cultuurdiensten van de
                            deelnemende gemeentes.
                        </p>
                    </Col> 
                </Row>
                <Row>
                    <Col sm={6} xs={12}>
                        <p>
                            Iedereen Dichter is een poging om met alle
                            gesprekken, schrijfsessies, gedichten en de
                            archivering hiervan grenzen te doorbreken van huis,
                            tuin, identiteit. Aan de hand van poëzie en beeld
                            probeert Astrid de gemoedstoestand van de
                            verschillende plaatsen op een bepaald moment te
                            vangen en door te geven. Daarnaast brengt het
                            project de mensen van de streek dichter én maakt
                            het van hen een dichter. De voorbijganger wordt
                            dichter, de dichter voorbijganger.
                        </p>
                        
                        <p>
                            Als bewoner van de hoofdstad probeert Astrid ook
                            een brug te slaan tussen stad en platteland. Aan de
                            hand van dit project denkt ze na over wat ons bindt
                            in tijden van anonimisering, angst voor het
                            onbekende en een steeds grotere kloof tussen
                            steden en dorpen.
                        </p>

                        <p>
                            Ze slaagde erin om via het project mensen dichter
                            te brengen, bij zichzelf, bij elkaar, bij de poëzie. Om
                            de mist van melancholie die over de velden en in de
                            mensen hangt aandacht te geven, de hartklop van
                            de streek die generatie op generatie wordt
                            doorgegeven samen met de inwoners te vatten en
                            om te zetten in iets nieuws.
                        </p>
                    </Col> 
                    <Col sm={6} xs={12}>
                        <img src={img3} alt="Iedereen dichter tekening"/>
                    </Col> 
                </Row>
                <Row>
                    <Col className="ProjectFooter" xs={6}>
                        <p className="blue">
                            Iedereen Dichter is een project in opdracht van Achthoek – cultuur in de noordelijke Westhoek
                            in samenwerking met het Poëziecentrum.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} xs={12}>
                        <img src={img4} alt="Iedereen dichter logo"/>
                        <p className="blue">© Gill Van Eeckhout</p>
                    </Col> 
                    <Col sm={6} xs={12}>
                        <p className="blue">
                        Met dank aan alle acht gemeentes en hun
                        cultuurdiensten, de betrokken inwoners,
                        basisscholen VBS Houthulst, VBS Lo-Reninge
                        en De Vierboete Nieuwpoort,
                        Guy en Nicole Florizoone, 4AD, Hugo
                        Verstraeten, Bruno, Betty, Dieter, Sylvie,
                        De school van Gaasbeek, Hilde Velghe,
                        Regine en Steven Haerens, Robbe Petitjean,
                        Joni de Borger, Leonardo van Dijl.
                        </p>
                    </Col> 
                </Row>
                <Row>
                    <Col className="ProjectFooter" xs={6}>
                        <p className="blue">
                        © Astrid Haerens 2019 – All rights reserved
                        </p>
                        <p className="blue">
                        website door Joni De Borger
                        </p>
                    </Col>
                </Row>
                
            </Container>
        </div>
    );
}

export default Project;