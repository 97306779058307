import React from 'react';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

//Logboek components
import Audio from '../components/Audio';
import Images from '../components/Images';
import Stack from '../components/Stack';
import VideoImage from '../components/VideoImage';

import { config } from '../config';
import '../../css/LogboekProject.css';

import logoDesktop from '../../assets/nav_logo.jpg';
import logoMobile from '../../assets/nav_logo_mobile.jpg';

const PROJECTS = config.projects;

function LogboekProject(props) {

    let logo = logoDesktop;
    let mobile = '';
    if(isMobile) {
        logo = logoMobile;
        mobile = 'mobile';
    }
    
    let { id } = useParams();

    let project = PROJECTS[id];
    let content = project.assets.map(function(asset){
        if( asset.type === 'stack') {
            return <Stack images={asset.images} placement={asset.placement}/>
        } else if (asset.type === 'video-image') {
            return <VideoImage image={asset.image} video={asset.video} placement={asset.placement}/>
        } else if (asset.type === 'images') {
            return <Images images={asset.images} size={asset.size} />
        } else if (asset.type === 'title') {
            return <h4 className="LogboekAssetTitle">{asset.text}</h4>
        } else if (asset.type === 'audio') {
            return <Audio id={asset.id} title={asset.title} />
        } else {
            return null
        }
    });

    return (
        <div className={`LogboekProject page ${mobile}`}>
            <div className="LogboekNav">
                <Link to="/">
                    <img className="LogboekLogo" src={logo} alt="Logo"/>
                </Link>
                <Link to="/logboek">
                <div className="hamburger hamburger--slider is-active">
                    <div className="hamburger-box">
                        <div className="hamburger-inner">
                        </div>
                    </div>
                </div>          
                </Link>  
            </div>
            <h1>{project.title}</h1>
            {content}
        </div>
    );
}

export default LogboekProject;