import React, { createContext, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Protect from 'react-app-protect';


import Film from './pages/Film';
import Home from './pages/Home';
import Logboek from './pages/Logboek';
import LogboekProject from './pages/LogboekProject';
import Project from './pages/Project';

import { Nav } from './components/Nav';
/* import 'bootstrap/dist/css/bootstrap.css';
import '../bootstrap-theme.css'; */
import '../css/App.css';

export const NavContext = createContext({ open: false });

function App() {

  const [navState, setNavState] = useState(false);


  return (
    <NavContext.Provider value={{
      navState,
      setNavState
    }}>
      <Router>
         <Protect
         sha512 = '07f314bb70f436807af6d74e47107cc66c86d4af249b9f3862e16906a2e5217647adcbec9d59669719c3e35f7021153c88fd2b6caff91b8456cd23de9b3595c8'
         className = "password"
         blur = {
             true
         }
         styles = {
                 {
                     input: {
                         color: "blue"
                     },
                     header: {
                         fontSize: "20px"
                     }
                 }
             } >
        <div className="App">
          <Nav/>
          <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/logboek" exact>
                <Logboek />
              </Route>
              <Route path="/logboek/project/:id">
                <LogboekProject />
              </Route>
              <Route path="/project" exact>
                <Project/>
              </Route>
              <Route path="/film" exact>
                <Film />
              </Route>
          </Switch>
        </div>
        </Protect>
      </Router>
    </NavContext.Provider>
  );
}

export default App;
