import Player from 'react-soundcloud-widget-player'
import React, { Component } from 'react'

class Audio extends Component {

  render () {
    return (
        <div className='AudioPlayer'>
          <Player ref="player"
            title={this.props.title}
            audioUrl={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${this.props.id}`} />
        </div>
    )
  }
}

export default Audio;
