import React, { Component } from 'react';

import MapComponent from '../components/MapComponent';

class Home extends Component {
    render() {
        return (
            <div className="Home page">
                <MapComponent/>
            </div>
        )
    }
}

export default Home;