import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { Player } from 'video-react';

import '../../css/Viewer.css';

const BASE_URL = "https://iedereendichter.s3.eu-central-1.amazonaws.com/";

class Viewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: props.project,
            playing: false,
            open: true
        }
        this.video = React.createRef()
    }

    componentDidMount () {
        console.log('video width', this.video)
    }

    render() {
        const project = this.state.project

        let mobile = '';
        if(isMobile) {
            mobile = 'mobile';
        }

        return (
            <div className="Viewer">
                <div className={`BackgroundVideo ${mobile}`}>
                    <Player
                        playsInline
                        src={BASE_URL+ project.video + "#t=0.1"}
                        autoPlay
                        loop
                        muted
                        preload="metadata"
                        ref={this.video}
                    />
                </div>
                <div className={`ViewerContent ${mobile}`}>
                    <span>
                        <h1>{project.title}</h1>
                        {project.text.map( (string, index) => (
                            <h5 key={`string_${index}`}>{string}</h5>
                        ))}
                    </span>
                </div>
                
                <div className="ViewerNav" onClick={() => this.props.onClose()}>
                    <div className="hamburger hamburger--slider is-active">
                        <div className="hamburger-box">
                            <div className="hamburger-inner">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Viewer