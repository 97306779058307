import React from 'react';

import '../../css/Images.css';

const BASE_URL = "https://iedereendichter.s3.eu-central-1.amazonaws.com";

function Images(props) {
    let childElements = props.images.map(function(image) {
        return <img className={`Image Size-${props.size}`} src={BASE_URL + image} alt={image}/>
    })

    return (
        <div className="Images">
            {childElements}
        </div>
    )
}

export default Images;