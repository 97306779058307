import React, { useContext } from 'react';
import {isMobile} from 'react-device-detect';
import { Link } from 'react-router-dom';

import logoDesktop from '../../assets/nav_logo.jpg';
import logoMobile from '../../assets/nav_logo_mobile.jpg';
import '../../css/Nav.css';

import { NavContext } from '../App';

function Nav() {
    
        const { navState, setNavState } = useContext(NavContext);
        let logo = logoDesktop;

        if(isMobile) {
            logo = logoMobile;
        }

        console.log("open", navState)
        return (
            <div className="NavContainer">
                <Link to="/">
                    <img className="NavLogo" src={logo} alt="Logo"/>
                </Link>
                <div className="NavBar">
                    <div className="NavHamburger" onClick={() => setNavState(!navState)}>
                        <div className={navState ? "hamburger hamburger--slider is-active" : "hamburger hamburger--slider"}>
                            <div className="hamburger-box">
                                <div className="hamburger-inner">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={navState ? 'NavMenu open' : 'NavMenu'}>
                    <ul>
                        <li>
                            <Link to="/" onClick={() => setNavState(false)}>
                                <h1>Kaart</h1>
                            </Link>
                            <Link to="/logboek" onClick={() => setNavState(false)}>
                                <h1>Logboek</h1>
                            </Link>
                            <Link to="/project" onClick={() => setNavState(false)}>
                                <h1>Project</h1>
                            </Link>
                            <Link to="/film" onClick={() => setNavState(false)}>
                                <h1>Film</h1>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        )
}

export { Nav, NavContext };
