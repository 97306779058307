import React, { useState } from 'react';
import Masonry from 'react-masonry-component';
import { Link } from 'react-router-dom';

import { config } from '../config';

const BASE_URL = "https://iedereendichter.s3.eu-central-1.amazonaws.com";
const PROJECTS = config.projects;

const imagesLoadedOptions = { background: '.my-bg-image-el' }

function Gallery(props) {
    const [hoverProject, setHoverProject] = useState(null);
    const images = props.images;
    
/*     setHoverProject = (projectId) => {
        this.setState({
            hoverProject: projectId
        });
    } */


    const masonryOptions = {
        transitionDuration: 0,
        originLeft: props.originLeft
    }

    const childElements = images.map(function(img){
        let active = '';
        let style = {
            transitionDelay: `${Math.random()/4}s`,
            WebkitTransitionDelay: `${Math.random()/4}s`, 
            msTransitionDelay: `${Math.random()/4}s`
        };
          
        if(img.projectId === hoverProject) {
            active = 'active';
        }

        if(PROJECTS[img.projectId].assets.length) {
            return (
                <li key={`img_${img.url}`}  className={"LogboekElement " + img.orientation + " " + active} onMouseEnter={() => setHoverProject(img.projectId)} onMouseLeave={() => setHoverProject(null)}>
                    <Link className='LogboekLink' to={`/logboek/project/${img.projectId}`}>
                        <div style={style} className="LogboekMenuWrapper">
                            <div className="LogboekMenuOverlay">
                                {img.projectTitle}
                            </div>
                            <img className="LogboekMenuImage" src={BASE_URL + img.url} alt="Logboek menu"/>
                        </div>
                    </Link>
                </li>
            );
        } else {
            //no link to project viewer
            return (
                <li key={`img_${img.url}`}  className={"LogboekElement " + img.orientation + " " + active} onMouseEnter={() => setHoverProject(img.projectId)} onMouseLeave={() => setHoverProject(null)}>
                    <div className='LogboekLink'>
                        <div style={style} className="LogboekMenuWrapper">
                            <div className="LogboekMenuOverlay">
                                {img.projectTitle}
                            </div>
                            <img className="LogboekMenuImage" src={BASE_URL + img.url} alt="Logboek menu"/>
                        </div>
                    </div>
                </li>
            );
        }

     });

    return (
        <div className="Gallery">
            <Masonry
                className={'LogboekMasonry'} // default ''
                options={masonryOptions} // default {}
                disableImagesLoaded={false} // default false
                updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                imagesLoadedOptions={imagesLoadedOptions} // default {}
            >
                { childElements }
            </Masonry>
        </div>
    )
}

export default Gallery;