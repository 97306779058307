import React from 'react';

import '../../css/VideoImage.css';

const BASE_URL = "https://iedereendichter.s3.eu-central-1.amazonaws.com";
function VideoImage(props) {

    return (
        <div className={`VideoImage ${props.placement}`}>
            <video className="Video" autoPlay loop muted>
                <source src={props.video} type="video/mp4" />
            </video>
            <img className="Image" src={BASE_URL + props.image} alt={props.image}/>
        </div>
    )
}

export default VideoImage;