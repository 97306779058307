import React, { Component } from 'react';

class Film extends Component {
    render() {
        return (
            <div className="Film page">
                <div className="FilmContainer">
                    <iframe title="Iedereen dichter" id="iedereendichter-vimeo-loader" src="https://player.vimeo.com/video/378526491" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
            </div>
        )
    }
}

export default Film;