import React, {Component} from 'react';
import { CircleMarker } from 'react-leaflet';

class  LocationMarker extends Component {
  render() {
    const { center, onClick, id } = this.props;
    let color = '#f3a683';

    let radius = 18;

    return (
      <span className='CustomMarker' onClick={() => onClick(id)} >
        <CircleMarker onClick={() => onClick(id)}  center={[center.lat, center.lng]} fillColor={color} fillOpacity={0} radius={radius*1} color={color} weight={2} stroke={true}/>
        <CircleMarker onClick={() => onClick(id)}  center={[center.lat, center.lng]} fillColor={color} fillOpacity={0.8}  radius={radius*0.8} stroke={false}/>
      </span>
    )
  }
}


export default LocationMarker;
