import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { NavContext } from '../App';

import Gallery from '../components/Gallery';
import { config } from '../config';

import '../../css/Logboek.css';

const IMAGES1 = config.menuAssets1;
const IMAGES2 = config.menuAssets2;
const IMAGES3 = config.menuAssets3;
const IMAGES4 = config.menuAssets4;
const IMAGES5 = config.menuAssets5;
const IMAGES6 = config.menuAssets6;

//const LogboekContext = React.createContext({ hoverProject: null});

function Logboek() {

    //const [hoverProject, setHoverProject] = useState(null);
    const  { navState } = useContext(NavContext);
    console.log('Nav context in Logboek', navState);

    return (
        <div className={navState ? "Logboek page menuOpen" : "Logboek page"}>
            <Container>
                <h1>{navState}</h1>
            <Row>
                <Col className="LogboekGalleryWrapper" md={12} lg={6}>
                    <Gallery images={IMAGES1} originLeft={false}/>
                </Col>
                <Col className="LogboekGalleryWrapper" md={12} lg={6}>
                    <Gallery images={IMAGES2} originLeft={true}/>
                </Col>
            </Row>
            <Row>
                <Col className="LogboekGalleryWrapper" md={12} lg={6}>
                    <Gallery images={IMAGES3} originLeft={false}/>
                </Col>
                <Col className="LogboekGalleryWrapper" md={12} lg={6}>
                    <Gallery images={IMAGES4} originLeft={true}/>
                </Col>
            </Row>
            <Row>
                <Col className="LogboekGalleryWrapper" md={12} lg={6}>
                    <Gallery images={IMAGES5} originLeft={false}/>
                </Col>
                <Col className="LogboekGalleryWrapper" md={12} lg={6}>
                    <Gallery images={IMAGES6} originLeft={true}/>
                </Col>
            </Row>
            </Container>
        </div>
    )
}

export default Logboek;